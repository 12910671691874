.container {
  max-width: 1140px;
}

/* Header start */

header {
  border-bottom: 1px solid #ececec;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 8;
}

.solution-list li {
  margin: 0;
}

.table thead th:last-child,
.table td:last-child {
  text-align: left !important;
}

.solution-list li span {
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  padding: 12px 15px;
  border-bottom: 1px solid #dfdfdf;
  cursor: pointer;
  transition: 0.4s;
  color: #4b7abe;
}

.paymentButtonsContainer.row {
  margin-top: 20px;
}

/* .react-draggable div {
	width: auto !important;
} */

.solution-list li span.active {
  color: #2db1ea;
}

footer .nav-link {
  font-size: 16px;
}

.solution-list li:hover span {
  color: #2db1ea;
  transition: 0.4s;
}

.solution-list li:last-child span {
  border-bottom: 0;
}

.solution-list {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
  position: sticky;
  top: 120px;
  padding: 10px 0;
}

.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root.btn.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  padding: 8px 20px !important;
  background: linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -ms-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  color: #fff !important;
  border: 0 !important;
  border-radius: 5px !important;
  transition: 0.4s;
  text-transform: capitalize;
  font-weight: 700;
}

.react-responsive-modal-modal label {
  font-weight: 700;
}

.MuiButton-root.MuiButton-primary.MuiButton-primaryPrimary.MuiButton-sizeMedium.MuiButton-primarySizeMedium.MuiButtonBase-root.btn.css-1w1rijm-MuiButtonBase-root-MuiButton-root:hover {
  background: linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -ms-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  transition: 0.4s;
}

.navigation {
  padding: 20px 0;
  position: relative;
  z-index: 1;
}

.navigation .row {
  align-items: center;
}

.nav-link {
  padding: 0;
}

.navbar {
  padding: 0;
}

nav ul li {
  margin-right: 30px;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
}

.navbar-expand .navbar-nav .nav-link {
  padding: 0;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 20px;
  color: #4b7abe;
  transition: 0.4s;
}

.navbar-expand .navbar-nav .nav-link:hover {
  color: #2db1ea;
  transition: 0.4s;
}

.navbar-expand .navbar-nav .nav-link.active {
  color: #2db1ea;
  transition: 0.4s;
}

.navbar-expand .navbar-nav .nav-link:hover {
  color: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%),
    linear-gradient(0deg, #060606, #060606);
  transition: 0.4s;
}

.navbar-expand .navbar-nav .nav-link:hover > * {
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.first-column ul li a {
  position: relative;
  top: 0;
  transition: 0.4s;
}

.dragArea {
  /* height: 40px !important; */
}

.bannerBox .App-logos {
  width: 100%;
}

.first-column ul li:hover a {
  top: -5px;
  transition: 0.4s;
}

.header_account ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header_account ul li {
  margin-right: 20px;
}

.header_account ul li:last-child {
  margin-right: 0;
}

.header_account ul li a {
  padding: 0;
}

header {
  transition: 0.4s;
}

.banner .row {
  align-items: center;
}

.menuscroll {
  background-color: #fff;
  transition: 0.4s;
}

.menuscroll .navigation {
  padding: 10px 0;
}

.header_account ul li.login a {
  padding: 8px 20px;
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  border-radius: 5px;
  transition: 0.4s;
}

.header_account {
  margin-left: 30px;
}

span.me {
  padding: 30px;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  z-index: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
}

.header_account ul li.login a:hover {
  background: linear-gradient(293.3deg, #29aee4 4.8%, #4b7abe 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  transition: 0.4s;
  color: #fff;
}

.main_navigation_menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Header end */

/* Banner start */

.banner {
  padding: 120px 0;
  position: relative;
  background: linear-gradient(180deg, #fafdff 0%, #ecf1ff 100%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  margin-top: 103px;
}

.banner h1 {
  padding-right: 30px;
}

.banner ul {
  display: flex;
  align-items: center;
}

.banner p {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 30px;
}

.banner ul li {
  margin-right: 20px;
}

.banner ul.labelList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.banner ul.labelList li {
  width: 50%;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
}

.banner ul.labelList li span {
  font-size: 18px;
  font-weight: 600;
  color: #231f20;
  display: inline-block;
  margin-left: 8px;
  text-transform: capitalize;
}

.banner ul li:last-child {
  margin-right: 0;
}

.banner ul li a.pdf {
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
}

.banner ul li a.pdf:hover {
  text-decoration: none;
  color: #000;
}

.banner h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 20px;
  color: #060606;
}

.line {
  display: inline-block;
  position: relative;
}

.banner .line:after {
  bottom: -27px;
}

.secureSection .line:after {
  bottom: -33px;
}

.featuresBox .line:after {
  bottom: -40px;
}

.solutionBox {
  margin-top: 100px;
  padding-bottom: 100px;
}

.solutionBox .line:after {
  bottom: -33px;
}

.solutionBox .row {
  align-items: center;
}

.popularFeatures .line:after {
  bottom: -40px;
}

.solutionImg {
  position: relative;
}

.solutionImg img {
  position: relative;
  right: -40px;
}

.elipse {
  position: absolute;
  top: -200px;
  right: 50px;
  display: block;
  width: 488px;
  height: 488px;
  z-index: -1;
}

.line:after {
  content: '';
  background: url(assets/images/line.svg) no-repeat;
  width: 154px;
  height: 52px;
  position: absolute;
  bottom: -35px;
  left: 0;
}

.home .text-gradient h2 > span {
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient h1 {
  color: #4b7abe;
}

/* .home .text-gradient h1 > span {
  background: linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);  
  background: -webkit-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  line-height: normal;
} */

/* .home .text-gradient h3 > span {
  background: linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);  
  background: -webkit-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 28px;
  line-height: normal;
} */

/* .home .text-gradient h5 > span {
  background: linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);  
  background: -webkit-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7Abe 4.8%, #29AEE4 95.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  
  font-weight: 600;
  font-size: 24px;
  line-height: normal;
} */

.text-gradient h5 {
  color: #4b7abe;
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
}

.home {
  overflow: hidden;
}

.banner a.nav-link,
.site_btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  color: #fff;
  transition: 0.4s;
  padding: 12px 26px;
  border-radius: 5px;
  text-transform: capitalize;
}

.banner a.nav-link:hover,
.site_btn:hover {
  background: linear-gradient(293.3deg, #29aee4 4.8%, #4b7abe 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  transition: 0.4s;
}

.banner a.nav-link:hover,
.site_btn:hover,
.form_box.pdf button:hover {
  background-color: #000;
  color: #fff;
  transition: 0.4s;
}

.title-name {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.highlight-image {
  width: 596px;
  top: 50%;
  left: -31%;
  /* -ms-transform: translate(-50%, -50%); */
  transform: translate(-5%, 35%);
}

.video_section:after {
  content: '';
  background: rgba(65, 212, 244, 0.2);
  filter: blur(190.8px);
  width: 318px;
  height: 318px;
  display: block;
  position: absolute;
  right: -60px;
  bottom: -170px;
  z-index: -1;
}

.icons_text p {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: #666666;
  margin-bottom: 0;
}

.bannerBox {
  position: relative;
}

.slideBox.one {
  display: flex;
  justify-content: center;
  align-items: center;
}

.esignature.product_tour {
  position: absolute;
  left: -110px;
  bottom: 44px;
}
.sliderBox {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.slideBox.one {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.two {
  margin-top: 60px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.swiper-slide-active .slideBox.one,
.swiper-slide-active .slideBox.two,
.swiper-slide-active .slideBox.three,
.swiper-slide-active .slideBox.four,
.swiper-slide-active .slideBox.five,
.swiper-slide-active .slideBox.six,
.swiper-slide-active .slideBox.seven,
.swiper-slide-active .slideBox.eight,
.swiper-slide-active .slideBox.nine,
.swiper-slide-active .slideBox.ten,
.swiper-slide-active .slideBox.eleven,
.swiper-slide-active .slideBox.tweleve,
.swiper-slide-active .slideBox.thriteen,
.swiper-slide-active .slideBox.forteen {
  opacity: 1;
}

.slideBox.three {
  margin-top: 60px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.three {
  margin-top: 60px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.four {
  margin-top: 60px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.five {
  margin-top: 60px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.six {
  margin-top: 60px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.seven {
  margin-top: 60px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.eight {
  margin-top: 32px;
  margin-left: 5px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.nine {
  margin-top: 32px;
  margin-left: 4px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.eleven {
  margin-top: 32px;
  margin-left: 4px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.tweleve {
  margin-top: 32px;
  margin-left: 4px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.thriteen {
  margin-top: 32px;
  margin-left: 4px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.forteen {
  margin-top: 32px;
  margin-left: 4px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.forteen img,
.slideBox.tweleve img,
.slideBox.eleven img,
.slideBox.eight img,
.slideBox.thriteen img,
.slideBox.nine img,
.slideBox.ten img {
  height: 337px;
  border-radius: 0 0 0 5px;
}

.slideBox.tweleve,
.slideBox.thriteen {
  margin: 32px 0 0 5px;
}

.slideBox.ten {
  margin-top: 32px;
  margin-left: 4px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slideBox.thirteen {
  margin-top: 60px;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.esignature {
  width: 311px;
  height: 101px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 12px 20px rgba(112, 112, 112, 0.14);
  margin-bottom: 24px;
  padding: 10px;
  position: absolute;
  left: -185px;
  bottom: 145px;
}

.header_dashboard_logo ul li a,
.header_dashboard_logo ul li span {
  font-size: 16px !important;
  font-weight: 600;
  line-height: 20px;
}

.header_dashboard_logo ul li a {
}

.header_dashboard_logo ul li span {
  color: #3895d2;
}

.header_dashboard_logo ul li a {
  color: #4b7abe;
}

.optionContainer .button_box.mb-4 {
  margin: 0 !important;
}

.esignature:last-child {
  margin-bottom: 0;
}

.icons {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(65, 212, 244, 0.24);
}

.esignature.product_tour .icons {
  background: rgba(244, 226, 65, 0.24);
}

.icons_text {
  width: 188px;
  margin-left: 16px;
}

/* Banner end */

/*  */

.secureSection {
  padding: 100px 0;
}

.scrolls {
  height: 500px;
  overflow: hidden;
}
/* .rfm-child {
  transform: none !important;
} */

.marquee-container {
  width: 100%;
  height: 500px; /* Adjust height as needed */
  overflow: hidden;
  /* border: 1px solid #ccc;  */
  padding: 20px;
}
.marquee-content {
  animation: marquee infinite linear;
}

@keyframes marquee {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.heightfix {
}

.secureSection .heightfix ul {
}

.join-text {
  position: sticky;
  top: 20px;
}

/* .secureSection .heightfix ul:after {
  content: '';
  background: url('assets/images/shadows.png') no-repeat;
  width: 100%;
  height: 302px;
  position: absolute;
  bottom: 0;
} */

.secureSection .heightfix ul li {
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
  padding: 24px;
  border-radius: 5px;
  margin-bottom: 24px;
}

.secureSection .heightfix ul li h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: #060606;
  margin-bottom: 24px;
}

.secureSection .heightfix ul li h4 svg {
  margin-right: 16px;
}

.secureSection .heightfix ul li p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #231f20;
}

.secureSection h3 {
  font-size: 34px;
  font-weight: 700;
  line-height: 51px;
  margin-bottom: 63px;
}

.join-text h3 {
  margin-bottom: 55px;
}

.text-gradient h3 {
  color: #4b7abe;
}

.solution h3 {
  font-size: 26px;
}

.hone .text-gradient h3 > span {
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.secureList {
  display: flex;
  flex-wrap: wrap;
}

.secureList li {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin: 0 18px 35px 0;
}

.secureList li:nth-child(3) {
  margin-right: 0;
}

.secureList li:last-child {
  margin-right: 0;
}

.secureList li svg {
  flex-shrink: 0;
}

.secureList li span {
  display: inline-block;
  flex-shrink: 0;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.text-gradient {
  position: relative;
  z-index: 2;
}

.featuresBox .text-gradient::after {
  content: '';
  background: url(assets/images/elipse.png) no-repeat center;
  width: 688px;
  height: 888px;
  display: block;
  position: absolute;
  top: 0;
  left: -180px;
  z-index: -1;
}

.featuresBox h3 {
  margin-bottom: 60px;
}

.masonaryview .row {
  align-items: center;
}

.masonaryview .row .col-md-6 {
  padding: 0 8px;
}

.blueBox {
  background: #f4f7ff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.blueBox h5 {
  font-size: 18px;
  font-weight: 600;
  color: #060606;
  margin: 5px 0 16px 0;
}

.blueBox p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #231f20;
  margin: 0;
}

.solutionBox h3,
.upcoming-feature-sec h3 {
  margin-bottom: 60px;
  color: #4b7abe;
}

.solutionBox li,
.upcoming-feature-sec li {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 30px;
}
.upcoming-feature-sec li {
  margin-bottom: 20px;
}

.solutionBox li span,
.upcoming-feature-sec li span {
  display: inline-block;
  margin-left: 8px;
}

.solutionImg {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

/*  */
/* businessSection start */

.businessSection {
  padding: 100px 0;
}

.businessSection h2 {
  margin-bottom: 40px;
  text-align: center;
}

.businessSection ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.businessSection ul li {
  margin-right: 40px;
}

.businessSection ul li:last-child {
  margin-right: 0;
}

/* businessSection end */

.eSignSection h2 {
  width: 100%;
  margin-bottom: 16px;
}

.border_shape {
  background: rgba(215, 223, 224, 0.2);
  padding: 22px;
  height: 100%;
  position: relative;
}

.border_shape:before {
  content: '';
  background: rgba(65, 212, 244, 0.2);
  filter: blur(190.8px);
  width: 318px;
  height: 311px;
  display: block;
  position: absolute;
  top: -25px;
  left: -125px;
  z-index: -1;
}

.whiteBox {
  background-color: #fff;
  height: 100%;
}

.popularFeatures {
  padding: 100px 0;
  background: #f4f7ff;
}

.popularFeatures h2.first_step_title {
  margin-bottom: 40px;
}

.popularFeatures button {
  position: relative;
}

.accordion-item {
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
  margin-bottom: 20px;
}

.faqText {
  position: relative;
}

.faqText h3 {
  margin-bottom: 60px;
}

.faqText span.borders {
  font-size: 18px;
  font-weight: 600;
  color: #231f20;
  position: relative;
  margin-bottom: 20px;
  padding-left: 95px;
}

.faqText span.borders:before {
  content: '';
  background-color: #231f20;
  width: 73px;
  height: 1px;
  display: block;
  position: absolute;
  top: 11px;
  left: 0;
}

.faqText p {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  color: #231f20;
  margin: 20px 0 0 0;
}

.arrows {
  position: absolute;
  bottom: 15px;
  left: 210px;
}

.accordion-item p {
  margin: 24px 0 0 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #231f20;
  padding-left: 32px;
}

.accordion-item button {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  background-color: inherit;
  border: 0;
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
}

.accordion-item button svg {
  flex-shrink: 0;
  margin-right: 8px;
}

.popularFeatures button.accordion-button.collapsed:after {
  content: '';
  background: url('assets/images/icon_plus.svg') no-repeat right center;
  background-size: contain;
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  transition: 0.4s;
  transform: rotate(0deg);
}

.popularFeatures button.accordion-button:after {
  content: '';
  background: url('assets/images/icon_minus.svg') no-repeat right center;
  background-size: contain;
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  transition: 0.4s;
  transform: rotate(180deg);
}

.popularFeatures .accordion-item:last-child {
  margin-bottom: 0;
}

.popularFeatures .site_btn {
  margin-top: 60px;
}

.popularFeatures button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding-right: 30px;
}

.popularFeatures .row {
  align-items: center;
}

.popularFeatures h2 {
  margin-bottom: 0;
}

.features_box_content {
  padding-left: 22px;
}

.features_box {
  width: 364px;
  background-color: #ffffff;
  border: 1px solid #000000;
  padding: 18px;
  display: flex;
  height: 100px;
  position: absolute;
  right: 0;
  bottom: 65px;
  align-items: center;
}

.features_box p {
  font-size: 12px;
  font-weight: 300;
  color: #666666;
  line-height: 18px;
  margin-bottom: 0;
}

.first-column a {
  display: block;
  padding: 0;
  margin-bottom: 20px;
}

.view_all {
  display: flex;
  justify-content: flex-end;
}

.view_all a {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  background-color: #f8e641;
  padding: 14px 34px;
  border: 1px solid #000;
}

.view_all a:hover {
  background: #000;
  color: #fff;
}

.border_box {
  padding: 30px;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
  margin-bottom: 25px;
  border-radius: 8px;
}

.border_box:last-child {
  margin-bottom: 0;
}

.upperBox {
  display: flex;
}

.upperBox h4 {
  margin-bottom: 0;
}

.upperBox span {
  width: 15%;
}

.upperBox span img {
  width: 100%;
}

.upperBox p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  padding-left: 24px;
  width: 85%;
}

.eSignSection {
  padding-left: 0;
  overflow: hidden;
}

.eSignSection .swiper-pointer-events {
  margin-left: 30px;
  overflow: visible;
}

.eSignSection .swiper p {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  margin-bottom: 0;
}

.banner_right svg {
  width: 100%;
}

.yellow {
  box-shadow: -10px 10px 0 #f5e75d;
}

.ml {
  margin-left: 15px;
}

.innerBox_row h3 {
  font-size: 26px;
  color: #4b7abe;
}

.optionContainer .button_box.mb-4 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  right: -80px;
}

.innerBox_row .work_flow h3 {
  font-weight: 700;
}

.innerBox_row .statistics h3 {
  font-weight: 700;
}

body .bhBox {
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
  top: 3px;
}

body .bhBox img {
  width: 15px;
  margin-right: 5px;
}

.swiper-slide.blue {
  box-shadow: -10px 10px 0 #4bd2f0 !important;
}

.orange {
  box-shadow: -10px 10px 0 #ff9432;
}

.swiper-slide.green {
  box-shadow: -10px 10px 0 #77ec90 !important;
}

.eSignSection .swiper-pagination {
  display: none;
}

.testimonial_video_box {
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.eSignSection .swiper img {
  margin: 0 auto 37px auto;
}

.eSignSection .swiper-slide {
  border: 1px solid #000000;
  padding: 50px 16px 32px 16px;
  margin-bottom: 10px;
  height: auto;
}

.swiper {
  padding-bottom: 10px;
}

.margin {
  margin-top: 42px;
}

.client_name {
  margin-top: 28px;
}

.client_name label {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #666666;
}

.faq {
  padding: 100px 0;
  background: linear-gradient(180deg, #fafdff 0%, #ecf1ff 100%);
}

.faq h2.faq_title {
  margin-bottom: 48px;
}

.faq .accordion {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.faq .accordianBoxes {
  width: 50%;
  padding: 0 16px;
}

.faq .accordianBoxesfull {
  width: 100%;
  padding: 0 16px;
}

.faq .accordion-item {
  padding: 32px 60px 32px 32px;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
  margin-bottom: 30px;
  background: #fff;
  position: relative;
  z-index: 0;
  border-radius: 8px;
}

.faq .accordion .accordion-item h2 button {
  text-align: left;
}

.faq .accordion-header {
  margin-bottom: 0;
}

.faq .accordion .accordion-item h2 button {
  display: block;
  width: 100%;
  border: 0;
  background-color: inherit;
}

.faq .accordion-body {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #231f20;
  padding: 0;
}

.faq h1 {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}

.faq .accordion-button {
  position: relative;
}

.faq .accordion-button:before {
  content: '';
  background-color: #f4f7ff;
  display: block;
  width: 36px;
  height: 36px;
  position: absolute;
  top: -3px;
  right: -31px;
  border-radius: 50px;
  display: none;
}

.faq .accordion-button.collapsed:before {
  content: '';
  right: -40px;
}

.faq .accordion-button:after {
  content: '';
  background: url('assets/images/icon_minus.svg') no-repeat right center;
  display: block;
  width: 36px;
  height: 36px;
  position: absolute;
  top: -3px;
  right: -36px;
  border-radius: 50px;
}

.faq .accordion-button.collapsed:after {
  content: '';
  background: url('assets/images/plus.svg') no-repeat right center;
}

.faq .accordion-button:after {
}

.faq .accordion-button.collapsed:after {
  right: -33px;
}

.getStarted {
  padding-bottom: 85px;
}

.getStarted h2 {
  margin-bottom: 24px;
}

.row_box {
  background-color: #d2f5fd;
  padding: 55px 105px 60px 55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 10px 10px 0px #000;
}

.row_box p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
}

.link_box {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.link_box:before {
  content: '';
  background: url('assets/images/started_arrow.svg') no-repeat right center;
  display: block;
  width: 102px;
  height: 75px;
  position: absolute;
  left: 78px;
  bottom: -15px;
}

.link_box a {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  line-height: 20px;
  background-color: #f8e641;
  padding: 14px 34px;
  border: 1px solid #000000;
}

.link_box a:hover {
  background: #000;
  color: #fff;
}

footer {
  background-color: #000000;
  padding: 60px 0 16px 0;
}

footer p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 22px;
  margin: 0;
}

footer h5,
footer a,
footer p {
  color: #ffffff;
}

footer a {
  font-weight: 400;
  margin-bottom: 15px;
  transition: 0.4s;
}

footer a:hover {
  color: #fff;
  text-decoration: underline;
  transition: 0.4s;
}

footer h5 {
  margin-bottom: 24px;
}

.first-column p {
  width: 250px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.first-column ul {
  display: flex;
  margin-top: 38px;
}

.first-column ul li {
  margin-right: 22px;
}

.first-column ul li:last-child {
  margin-right: 0;
}

footer .form-control {
  background-color: inherit;
  border: 1px solid #ececec;
  color: rgba(255, 255, 255, 0.44);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 13px 16px;
  height: 45px;
  display: block;
  border-radius: 5px;
}

footer button {
  color: #000000;
  border: 0;
  background: url(assets/images/plane.svg) no-repeat 95% 55% #f8e641;
  display: block;
  margin-top: 16px;
}

footer .footerBottom p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.footerBottom {
  border-top: 1px solid rgba(102, 102, 102, 0.14);
  padding-top: 16px;
  margin-top: 47px;
}

.footerBottom .container {
  border-top: 1px solid #515151;
  padding-top: 24px;
}

.footerBottom .row {
  justify-content: space-between;
}

.footerBottom ul {
  display: flex;
  justify-content: flex-end;
}

.footerBottom ul li {
  margin-right: 20px;
}

.footerBottom ul li:last-child {
  margin-right: 0;
}

.footerBottom ul li a {
  margin: 0;
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 5px;
}

.footerBottom ul li a:hover {
  background: linear-gradient(293.3deg, #29aee4 4.8%, #4b7abe 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
}

.footerBottom ul li img {
  filter: brightness(100);
}

.define-1 {
  transform: translateX(0);
  transition: all 1.5s;
}

.content_box {
  min-height: 600px;
  padding: 60px 0;
  background: linear-gradient(180deg, #fafdff 0%, #ecf1ff 100%);
  max-width: 100%;
}

.container.content_box.mt-5 {
  max-width: 100%;
}

.container.content_box.mt-5 .form_main_box.px-0.py-3 {
  padding: 20px !important;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  z-index: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
  margin-top: 80px;
}

.container.content_box.mt-5 .form_main_box.px-0.py-3 .btn-primary {
  font-size: 16px;
  line-height: normal;
  padding: 8px 20px;
}

.sticky-bar h5 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  color: #4b7abe;
  text-align: left;
}

body .start_area a {
  padding: 8px 20px;
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  font-size: 16px;
  line-height: 20px;
  color: #fff !important;
  border-radius: 5px;
  transition: 0.4s;
  border: 0;
}

.container.content_box.mt-5 .form_main_box.px-0.py-3 label.file_title {
  margin-bottom: 0 !important;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  position: relative;
  color: #4b7abe;
}

.container.content_box.mt-5 .form_main_box.px-0.py-3 label {
  color: #4b7abe;
  font-size: 18px;
}

.container.content_box.mt-5 .col-7.m-auto {
  border: 0 !important;
  padding: 0 !important;
}

.container.content_box.mt-5 .form_main_box.px-0.py-3 div {
  color: #231f20 !important;
  margin-bottom: 10px;
}

.container.content_box.mt-5 .form_main_box.px-0.py-3 div.row div.col-3 {
  font-weight: 700;
}

.container.content_box.mt-5 .form_main_box.px-0.py-3 div div {
  margin-bottom: 0;
  padding: 0 5px;
}

body .btn-primary:hover {
  background: -ms-linear-gradient(293.3deg, #29aee4 4.8%, #4b7abe 95.03%);
}

.container.content_box.mt-5 .form_main_box.px-0.py-3 div.row div {
  margin-bottom: 10px;
}

.container.content_box.mt-5
  .form_main_box.px-0.py-3
  div.row
  div.text-secondary {
  padding-left: 10px;
}

body .form_main_box .filesList.form-control.file_list_main code {
  padding: 0 !important;
}

.pdf_format h1 {
  margin-top: 60px;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
}

.content_box h1 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  color: #4b7abe;
}

.form_box form {
  padding: 30px;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  z-index: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
}

.form_box::before {
  content: '';
  background: rgba(65, 212, 244, 0.4);
  filter: blur(190.8px);
  width: 300px;
  height: 300px;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  margin: 0 auto;
}

/* .form_box::after {
  content: '';
  background-color: rgba(244, 226, 65, 0.8);
  filter: blur(190.8px);
  display: block;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 30px;
  left: 220px;
  right: inherit;
  margin: 0 auto;
  text-align: center;
  z-index: -1;
} */

.form_box.signup form {
  max-width: 800px;
}

.footerBottom ul li.website {
  position: relative;
  background: url('assets/images/heart.svg') no-repeat left center;
  padding-left: 22px;
}

.login_section a {
  margin-bottom: 20px;
}

.app {
  width: 100vw;
  height: 100vh;
}

.app .header {
  height: 5rem;
  width: 100%;
  background-color: #292929;
  padding: 1rem;
  box-sizing: border-box;
  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .menu-btn {
  font-size: 1.75rem;
  cursor: pointer;
}

.header .nav {
  display: flex;
  gap: 1rem;
}

.app .body {
  height: calc(100% - 5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.app .body button {
  padding: 0.5rem;
  font-size: 1.5rem;
  border-radius: 10px;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 50vh;
  min-width: 20rem;
  min-height: 25rem;
  background-color: rgb(228, 228, 228);
  border-radius: 10px;
  z-index: 200;

  display: flex;
  flex-direction: column;
}

.modal .modal-header {
  height: 2.5rem;
  border-radius: 10px 10px 0 0;
  background-color: rgb(29, 29, 29);
  position: relative;
}

.modal .modal-header h2 {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0.4rem;
  cursor: pointer;
}

.modal .modal-body {
  height: 100%;
  display: grid;
  place-content: center;
}

.app-menu {
  color: white;
  height: 100vh;
  width: 20rem;
  background-color: #292929;
  z-index: 200;

  transform: translateX(-100%);
  transition: 0.3s;

  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
}

.menu-open {
  transform: translateX(0%);
}

.mobile {
  display: none;
}

.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sticky {
  position: sticky;
  top: 150px;
}

.menu-bars {
  margin-left: 0;
  font-size: 24px;
  background: none;
}

.nav-menu {
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 9;
  transition: 850ms;
  background: #fff;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-meu-items {
  width: 100%;
}

.nav-text {
  display: block;
  list-style: none;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #ececec;
}

.nav-text a {
  color: #ffffff;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.pdf_format.full_width .right_column {
  width: 100%;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pdf {
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
}

.pdf input.form-control {
  height: 55px;
  padding: 15px;
  margin-bottom: 15px;
}

.logout .btn-success {
  padding: 0;
  border: 0;
  background-color: inherit;
}

.logout .btn-success:not(:disabled):not(.disabled).active,
.logout .btn-success:not(:disabled):not(.disabled):active,
.logout .show > .btn-success.dropdown-toggle {
  border: 0;
  background-color: inherit;
}

.logout .btn-success:not(:disabled):not(.disabled).active:focus,
.logout .btn-success:not(:disabled):not(.disabled):active:focus,
.logout .show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.logout .dropdown-item {
  padding: 10px 10px;
  border-bottom: 1px solid #dfdfdf;
  font-size: 14px;
}

.logout .dropdown-item a {
  padding: 0;
}

.logout .dropdown-toggle::after {
  display: none;
}

.margin_box .form-check.doc-background:first-child {
  background: url('assets/images/icon_signing.svg') no-repeat 98% 50% #9cc4fd !important;
}

.margin_box .form-check:first-child {
  background: url('assets/images/icon_signing.svg') no-repeat 98% 50% #f4f7ff;
}

.margin_box .form-check-publish:first-child {
  background: url('assets/images/icon_publishing.svg') no-repeat 98% 50%;
}

.margin_box .form-check:nth-child(2) {
  background: url('assets/images/icon_publishing.svg') no-repeat 98% 50%;
}

.margin_box .form-check-test:last-child {
  background: url('assets/images/icon_sharing.svg') no-repeat 98% 50%;
}

.margin_box .form-check-publish {
  background: url('assets/images/icon_publishing.svg') no-repeat 98% 50%;
}

.margin_box .form-check-signing {
  background: url('assets/images/icon_signing.svg') no-repeat 98% 50%;
}

.optionContainer.iconPopups .margin_box .form-check:first-child,
.optionContainer.iconPopups .margin_box .form-check:nth-child(3),
.optionContainer.iconPopups .margin_box .form-check:nth-child(2),
.optionContainer.iconPopups .margin_box .form-check:last-child {
  background: none;
}

.margin_box .form-check {
  position: relative;
  display: flex;
  padding-left: 0;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 17px 15px;
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: normal;
}

.actionStyle span {
  margin-left: 10px;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
  width: 20px;
  height: 20px;
}

.visually-hidden {
  height: 60px;
  width: auto;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  margin-top: 12px;
  font-weight: 400;
  margin-right: 0;
}

.choose_files {
  position: absolute;
  left: 15px;
  color: #000;
  border: 1px solid #000;
  padding: 16px 16px 16px 50px;
  font-weight: 700;
  margin-bottom: 0;
  background: url(assets/images/icon_upload.svg) no-repeat #f8e641;
  background-position: 11% 50%;
  background-size: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Separate rule for compatibility, :focus-within is required on modern Firefox and Chrome */
input.visually-hidden:focus + label {
  outline: thin dotted;
}

input.visually-hidden:focus-within + label {
  outline: thin dotted;
}

.form-check-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #7b1fa2;
}

.form_box.pdf {
  padding: 0;
  max-width: 650px;
}

.form_box.pdf h5 {
  margin-bottom: 15px;
}

.form_box.pdf .form-check-label {
  cursor: pointer;
  font-weight: 600;
  margin-left: 8px;
}

.form_box.pdf form {
  padding: 30px 10px;
  max-width: inherit;
  background-color: #fff;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
}

.doc-background {
  background-color: #f4f7ff !important;
}

.margin_box {
  margin-bottom: 30px;
}

.margin_box label {
  font-weight: 700;
}

.margin_box.popup .form-check:nth-child(3) {
  background: url('assets/images/esignatures.svg') no-repeat 98% 50%;
}

.image_none .form-check {
  align-items: center;
  border: 1px solid #dfdfdf;
  padding: 17px 15px;
  margin-bottom: 15px;
}

.form-check-label {
  cursor: pointer;
}

.form_box.pdf button {
  text-align: center;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -moz-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -webkit-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -o-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
}

.form_box.pdf button:hover {
  background: linear-gradient(293.3deg, #29aee4 4.8%, #4b7abe 95.03%);
}

.form_box.pdf button img {
  filter: brightness(100);
}

.form_box.pdf button img {
  width: 20px;
  margin-right: 10px;
  filter: brightness(100);
}

.header_box {
  padding: 13px 118px;
  border-bottom: 1px solid #dfdfdf;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 8;
  background-color: #fff;
}

.pdf_name {
  width: auto;
  max-width: 500px;
  height: 50px;
  border: 1px solid #dfdfdf;
  margin: 35px auto 35px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  font-weight: 700;
  word-break: break-all;
}

.pdf_format {
  margin-top: 96px;
  max-width: 100%;
  padding: 0 60px;
}

.header_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

body .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #4b7abe;
  border-color: #4b7abe;
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
}

.header_dashboard_logo ul {
  display: flex;
  align-items: center;
}

.header_dashboard_logo ul li {
  margin-right: 24px;
}

.header_dashboard_logo ul li:last-child {
  margin-right: 0;
}

.header_dashboard_logo ul li span {
  font-size: 16px;
  font-weight: 600;
  color: #2db1ea;
  line-height: normal;
  text-transform: capitalize;
}

.header_dashboard_logo ul li.logout span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f7ff;
}

.pdf_format {
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 1;
}

.pdf_format.dashboard_box_content.main_area {
}

.main_area .dashboard_left.left_column {
  width: 15%;
  display: block;
  background: #f4f7ff;
}

.main_area .dashboard_right.right_column {
}

.left_column {
  width: 93%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background: #f4f7ff;
}

.sidebar.active {
  background: #fff !important;
}

.left_column a {
  display: block;
  width: 13%;
}

.left_column .sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  font-size: 16px;
  font-weight: 500;
}

.form_inner_box .visually-hidden {
  width: 0;
  height: 0;
  margin: 0;
}

.min_height .innerBox {
  padding: 0 50px;
}

.right_column {
  width: 70%;
  background-color: #ffffff;
  padding: 60px;
  margin: 0 auto;
  height: 100vh;
  overflow-y: auto;
}

.right_column.min_height {
  height: inherit;
}

.right_column.profiles {
  height: inherit;
}

.right_column.profiles .innerBox.tables {
  height: inherit !important;
}

.right_column.profiles .innerBox.tables .P2P {
  margin: 0 !important;
  display: flex;
  flex-wrap: wrap;
}

.right_column.profiles .innerBox.tables .P2P {
}

.right_column.profiles .innerBox.tables .P2P h1 {
  width: 100%;
  margin: 20px 0 30px 0;
}

.right_column.profiles .innerBox.tables .P2P .form-group {
  margin: 0 0 20px 0 !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
  padding: 0 15px;
  width: 50%;
  margin-bottom: 20px;
}

.right_column.profiles .innerBox.tables .Escrow.P2P button {
  margin: 0 0 0 15px !important;
  width: 100% !important;
}

/* .pdf_format.full_width .right_column.profiles {
  background-color: #ffffff !important;
  height: 100% !important;
  width: 93% !important;
  box-shadow: 0px 0px 30px 2px #4b7abe1a !important;
  padding: 15px !important;
  border-radius: 5px !important;
  margin-top: 60px;
} */

.pdf_format.full_width .right_column.profiles {
  height: inherit !important;
}

.right_column.profiles .innerBox.tables .Escrow.P2P button {
  width: 100% !important;
}

.right_column.profiles .innerBox.tables .P2P .form-group label {
  font-size: 14px;
  font-weight: 700;
  color: #231f20 !important;
  margin: 0 0 15px 0 !important;
}

.right_column.profiles .innerBox.tables .P2P .form-group input,
.right_column.profiles .innerBox.tables .P2P .form-group select {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 10px !important;
  border: 1px solid #ececec;
  height: 42px;
}

.right_column.profiles .innerBox.tables .P2P .form-group div {
  display: block;
  width: 100%;
}

.paymentButtonsContainer.row button {
  margin-left: 0 !important;
}

.paymentButtonsContainer.row .button_box {
  display: inline-block !important;
  position: static;
  width: auto;
  max-width: inherit;
  flex: inherit;
}

.paymentButtonsContainer.row {
  justify-content: center;
}

.right_column.profiles .innerBox.tables .P2P .form-group {
  position: relative;
}

.right_column.profiles .innerBox.tables .P2P .form-group p {
  position: absolute;
  left: 0;
  bottom: -17px;
  margin: 0 0 0 15px !important;
}

.paymentButtonsContainer.row {
  flex-wrap: wrap;
}

.paymentButtonsContainer.row .button_box.mb-4 {
  margin-bottom: 15px !important;
}

.right_column.profiles .innerBox.tables .P2P .form-group label {
  margin: 0 !important;
  display: block !important;
  margin: 0 0 10px 0 !important;
}

.right_column.profiles .innerBox.tables .P2P .button_box button {
  margin: 0 0 0 15px !important;
  width: auto !important;
}

.right_column.profiles .innerBox.tables .P2P .button_box.mb-4 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.siderbarPopup {
  width: 15%;
}

.innerBox {
  background-color: #ffffff;
  height: 100%;
  width: 93%;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
  padding: 15px;
  border-radius: 5px;
}

.left_column .sidebar span {
  display: block;
  margin-top: 20px;
}

.form_inner_box .d-block {
  margin-top: 20px;
}

.center_button {
  display: flex;
  justify-content: center;
}

.center_button button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ececec;
  background-color: #f4f7ff;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.center_button button img {
  margin-right: 10px;
  width: 35px;
}

.innerBox_row {
  padding: 30px 0;
}

.form_name {
  max-width: 900px;
  margin: 80px auto 80px auto;
  width: 100%;
  position: relative;
}

.form_inner_box {
  text-align: center;
  border: 1px dotted #dfdfdf;
  padding: 40px;
  position: relative;
}

.form_inner_box .choose_files {
  left: 0;
  right: 0;
  margin: 0 auto;
  width: auto;
  position: static;
}

.svg {
  margin-bottom: 20px;
}

.button_box button {
  border: 0;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 13px 55px 13px 25px;
  /* background: url(assets/images/icon_uploads.png) no-repeat 80% 50% #4b7Abe; */
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -ms-linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  border-radius: 5px;
  position: relative;
  transition: 0.4s;
}

.button_box button:after {
  content: '';
  background: url(assets/images/icon_uploads.png) no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 12px;
  right: 22px;
}

.button_box button:hover {
  background: linear-gradient(293.3deg, #29aee4 4.8%, #4b7abe 95.03%);
  background: linear-gradient(293.3deg, #29aee4 4.8%, #4b7abe 95.03%);
  transition: 0.4s;
}

.innerBox table {
  margin-bottom: 0;
}

.innerBox.tables {
  padding: 30px;
  margin: 0 auto;
}

.table .form-check-input {
  position: static;
  width: 20px;
  height: 20px;
  margin: 0;
}

table .dropdown-toggle::after {
  display: none;
}

table .btn-success {
  background-color: inherit;
  border: 0;
  padding: 0;
}

table .btn-success:not(:disabled):not(.disabled).active,
table .btn-success:not(:disabled):not(.disabled):active,
table .show > .btn-success.dropdown-toggle {
  background-color: inherit;
  border: 0;
}

table .btn-success:hover {
  border: 0;
  background-color: inherit;
}

table .btn-success:not(:disabled):not(.disabled).active:focus,
table .btn-success:not(:disabled):not(.disabled):active:focus,
table .show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

table .btn-success.focus,
table .btn-success:focus {
  box-shadow: none;
}

table .dropdown-item {
  font-size: 12px;
  font-weight: 300;
  color: #000;
  line-height: normal;
  padding: 5px 0 5px 15px;
  text-transform: capitalize;
}

table .dropdown-menu {
  margin: 10px 0 0 0;
}

.left_column .sidebar img {
  width: 40px;
}

.right_column .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #ffffff;
}

.right_column .table thead th {
  border: 0;
}

.sorticon {
  width: 5%;
  float: right;
  cursor: 'pointer';
}

.right_column .table thead th {
  border-bottom: 1px solid #ececec;
}

.right_column .table-hover tbody tr:hover {
  background-color: #ffffff;
  color: #000;
}

.right_column table .btn-success {
  border: 1px solid #dfdfdf;
  padding: 2px 3px;
}

.right_column table .btn-success:not(:disabled):not(.disabled).active,
.right_column table .btn-success:not(:disabled):not(.disabled):active,
.right_column table .show > .btn-success.dropdown-toggle {
  border: 1px solid #dfdfdf;
}

.right_column .table-bordered td td {
  border: 0;
  padding: 0 10px;
}

.right_column .table-bordered td td span {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 0.25rem;
}

.right_column table .btn-success:hover {
  border: 1px solid #dfdfdf;
}

.right_column .table .form-check-input {
  cursor: pointer;
}

.right_column .table-bordered td,
.right_column .table-bordered th {
  border: 0;
  vertical-align: middle;
  border-bottom: 1px solid #dfdfdf;
  padding: 15px;
}

.right_column table .dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 10px;
}

.right_column table .dropdown-item img {
  width: 18px;
  margin-right: 10px;
}

.bottom_table {
  margin-top: 30px;
}

.bottom_table ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bottom_table ul li {
  margin-right: 20px;
}

.bottom_table ul li:last-child {
  margin-right: 0;
}

.content_box.mt-5 .col-md-7.m-md-auto.mx-4 {
  border: 0 !important;
}

.top_table_boxes {
  margin-bottom: 20px;
}

.top_table_boxes h2 {
  font-weight: 700;
  color: #4b7abe;
}

.profiles h2 {
  font-weight: 700;
  color: #4b7abe;
}

.top_table_boxes .row {
  align-items: center;
}

.top_table_boxes ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top_table_boxes ul li {
  margin-right: 15px;
}

.top_table_boxes ul li:last-child {
  margin-right: 0;
}

.innerBox table {
  border: 1px solid #ececec;
}

.top_table_boxes ul li select {
  background-color: #ffffff;
  border: 1px solid #ececec;
  cursor: pointer;
  padding: 10px;
}

.bottom_table ul li span {
  margin-right: 10px;
}

.bottom_table ul li select {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  padding: 8px;
}

.bottom_table ul li:last-child span {
  margin-right: 0;
}

.bottom_table ul li:last-child span {
  background-color: #f8e641;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
}

.right_column .table-bordered td.position_icons {
  position: relative;
}

.right_column .table-bordered td.position_icons ul {
  position: absolute;
  top: 25px;
  right: 0;
  display: none;
}

.right_column .innerBox table .doc_row:hover ul {
  display: flex;
}

.right_column .table-bordered td.position_icons ul li {
  margin-right: 15px;
}

.right_column .table-bordered td.position_icons ul li:last-child {
  margin-right: 0;
}

.right_column .table-bordered td.position_icons ul li svg {
  cursor: pointer;
}

.image_section {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.icon_grid {
  background: #f4f7ff;
  width: 80px;
  height: 80px;
  padding: 10px;
  border-radius: 50%;
  border: 1px dashed #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mt-4 button {
  background: linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -moz-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -webkit-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -o-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -ms-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  color: #fff;
  border: 0 !important;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}

.mt-4 button:hover {
  background: linear-gradient(
    293.3deg,
    #29aee4 4.8%,
    #4b7abe 95.03%
  ) !important;
  background: -moz-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -webkit-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -o-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -ms-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
}

.image_section span {
  font-size: 16px;
  font-weight: 300;
  text-transform: capitalize;
  display: block;
  margin: 0 0 0 20px;
  cursor: pointer;
}

.innerBox.tables h5 {
  margin: 25px 0;
}

.innerBox.tables input {
  color: #000000;
}

.innerBox.tables input:focus {
  box-shadow: none;
}

.profile_section {
  margin-top: 10px;
}

.main_area .left_column {
  padding: 0;
  position: fixed;
  background-color: #fff;
  z-index: 9;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 100px;
}

.form_table_section button {
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -moz-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -webkit-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -o-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -ms-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 25px;
  text-transform: capitalize;
}

.form_table_section button:hover {
  background: linear-gradient(293.3deg, #29aee4 4.8%, #4b7abe 95.03%);
}

.form_table_section label {
  cursor: pointer;
  display: block;
  margin-top: 15px;
  text-align: right;
  text-decoration: underline;
}

.innerBox.tables h6 {
  margin: 30px 0 15px 0;
}

.pdf_row {
  padding: 25px 0;
  border-bottom: 1px solid #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.pdf_row span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 12px;
}

.pdf_row:nth-child(6),
.pdf_row:last-child {
  border: 0;
}

.left_column a:hover {
  color: #000;
  text-decoration: none;
}

.optionContainer .button_box button {
  background: linear-gradient(293.3deg, #4b7abe 4.8%, #29aee4 95.03%);
  background: -moz-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -webkit-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -o-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  background: -ms-linear-gradient(
    293.3deg,
    #4b7abe 4.8%,
    #29aee4 95.03%
  ) !important;
  position: relative;
  z-index: 9;
  color: #fff;
  border: 0;
  padding: 10px 35px 10px 20px;
  border-radius: 5px;
}

.optionContainer .button_box button:hover {
  background: linear-gradient(293.3deg, #29aee4 4.8%, #4b7abe 95.03%);
}

.optionContainer .button_box button:after {
  content: '';
  background: url('assets/images/next-arrow.png') no-repeat right center;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 12px;
  right: 13px;
}

.login_section.main_section {
  margin-top: 60px;
}

.signer_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.form_main_box {
  position: relative;
}

.position_minus {
  position: absolute;
  top: 0;
  right: 0;
}

.form_box.pdf .signer_title h5 {
  margin: 0;
}

.last_child .btn-primary {
  text-align: center;
  border: 1px solid #000;
}

.pdf_row.last_child {
  border: 0;
  padding: 0 24px;
  margin: 25px 0 80px 0;
}

.pdf_row:last-child {
  margin-top: 25px;
  margin-bottom: 80px;
  padding: 0 24px;
}

.pdf_row .btn-primary:not(:disabled):not(.disabled).active,
.pdf_row .btn-primary:not(:disabled):not(.disabled):active,
.pdf_row .show > .btn-primary.dropdown-toggle {
  background-color: #000000;
  color: #ffffff;
}

.pdf_row .btn-primary:not(:disabled):not(.disabled).active:focus,
.pdf_row .btn-primary:not(:disabled):not(.disabled):active:focus,
.pdf_row .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.pdf_row .btn-primary.focus,
.pdf_row .btn-primary:focus {
  box-shadow: none;
}

.bottom_border {
  padding: 0 24px;
  margin-bottom: 27px;
}

.inner_border_box {
  border: 1px dashed rgba(0, 0, 0, 0.24);
  padding: 20px;
}

.inner_border_box h6 {
  margin-bottom: 14px;
}

.inner_border_box p {
  margin: 0 0 10px 0;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 15px;
}

.inner_border_box p:last-child {
  margin-bottom: 0;
}

/* .dragArea {
  background: #c5d1f0 !important;
} */

/* .pdf_row select {
  width: 70%;
  border: 0;
  height: 45px;
  padding: 0 15px;
  text-transform: capitalize;
  border-radius: 5px;
  background: #4b7Abe;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.04em;
  margin: 0;
} */

.pdf_row select {
  background: url(assets/images/arrow-down-bold.svg) no-repeat right 15px center
    #4b7abe;
  background-size: 14px;
  display: block;
  width: 70%;
  border: 0;
  height: 45px;
  padding: 0 15px;
  text-transform: capitalize;
  border-radius: 5px;
  appearance: none;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.form_box.email_verfication {
  background: #fff;
  max-width: 500px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 30px 15px;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
}

.form_box.email_verfication p {
  color: #000;
}

.cursor-pointer {
  cursor: pointer;
}

.siderbarPopup {
  position: fixed;
  top: 140px;
  right: 0;
  background: #fff;
  z-index: 9;
  height: 100vh;
}

.top_title {
  display: flex;
  color: #000;
  align-items: center;
  padding: 0;
}

.siderbarPopup .field.common_section div {
  color: #000;
}

.siderbarPopup .form-control:focus {
  color: #000;
}

.form-control,
input {
  color: #000;
}

.top_title svg {
  fill: #fff;
}

.pdf_row h6 {
  margin-bottom: 15px;
  font-size: 26px;
  font-weight: 600;
}

.siderbarPopup h6 {
  font-size: 14px;
  margin-bottom: 15px;
}

.siderbarPopup .top_title h6 {
  font-size: 14px;
  margin-bottom: 0;
}

.common_section {
  border-bottom: 1px solid #dfdfdf;
  padding: 20px 0;
}

.siderbarPopup select {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  padding: 10px;
}

.formatting.common_section select {
  margin-bottom: 15px;
}

.formatting.common_section select:last-child {
  margin-bottom: 0;
}

/* dots */

.dots {
  background-color: #ffffff;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  border: 1px solid #b50001;
  position: absolute;
  cursor: w-resize;
}

.dots.one {
  top: -4px;
  left: -4px;
  background: #f4f7ff !important;
}

.dots.three {
  /* bottom: -4px; */
  left: -4px;
  background: #f4f7ff !important;
}

.dots.four {
  /* bottom: -4px; */
  right: -4px;
  background: #f4f7ff !important;
}

.react-draggable {
  border: 2px solid #f4f7ff !important;
  /* height: 40px !important; */
}

.closeicon {
  background: url(assets/images/plus.svg) no-repeat center #f4f7ff !important;
  background-size: contain;
  border: 1px solid #b50001;
  border-radius: 50px;
  height: 13px;
  width: 13px;
  padding: 5px;
  position: absolute;
  top: -8px;
  right: -8px;
  transform: rotate(45deg);
  cursor: pointer;
}

.logout .dropdown-menu.show {
  padding: 0;
}
.rows:after {
  display: contents;
  clear: both;
}
.columns {
  float: left;
  width: 40%;
}

.faq,
.contact,
.content_box {
  margin-top: 103px;
}

/* feature page style */
.feature.content_box p {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 30px;
}
.feature.content_box p:last-child {
  margin-bottom: 0;
}
.feature.content_box h3 {
  font-size: 26px;
  line-height: 38px;
  margin-bottom: 10px;
  font-weight: 700;
}
.feature .content-box,
.feature .content-box-even {
  position: relative;
  box-shadow: 0px 0px 30px 2px #4b7abe1a;
  background: #fff;
  padding: 15px 30px;
  border-radius: 10px;
  margin-bottom: 40px;
}

/* solution page style */
.solution_box {
  position: relative;
  background: #fff;
  margin-bottom: 25px;
  border-radius: 8px;
}
.solution_box p,
.solution_box li {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
}
.solution_box p:last-child {
  margin-bottom: 0;
}
.solution_box ul {
  padding-left: 15px;
}
.solution_box ul li {
  list-style: disc;
  margin-bottom: 0;
  color: #666666;
}

.react-responsive-modal-modal {
  padding: 30px 30px 20px;
  max-width: 800px;
  width: 100%;
}

.form_boxes.page_not_found {
  margin-top: 60px;
}
.swiper-wrapper,
.swiper {
  z-index: 0;
}

.add-signer .hvr-icon,
.add-signer:hover .app-logo {
  display: none;
}

.add-signer:hover .hvr-icon {
  display: block;
}
.header_account li.signUp a {
  word-break: break-all;
  font-weight: 600;
  font-size: 16px;
  color: #2db1ea;
  text-transform: capitalize;
}

.sticky-bar {
  position: fixed;
  top: 76px;
  margin: 0 auto;
  background: #fff;
  width: 100%;
  padding-left: 35px;
  padding-top: 22px;
  z-index: 99;
  padding-right: 81px;
}

.form_box.pdf.display_form_data {
  margin-top: 160px !important;
}

.form_box.pdf.display_form_data:before {
  bottom: inherit;
  top: 35%;
}

.form_box.pdf.display_form_data .form-label.file_title {
  display: none;
}

.form_box.pdf.display_form_data .form_main_box label {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: #4b7abe;
  margin-bottom: 15px;
}

.form_box.pdf.display_form_data .form_main_box .form-group {
  margin-bottom: 0;
}

.form_box.pdf.display_form_data .form_main_box .d-flex {
  margin: 0 -12px;
  justify-content: flex-start !important;
}

.form_box.pdf.display_form_data .form_main_box .d-flex div {
  margin-bottom: 10px;
  color: #231f20 !important;
  margin: 0 0 10px 0;
}

.form_box.pdf.display_form_data .form_main_box .d-flex div.col-sm-3 {
  font-weight: 700;
  color: #231f20;
}

.col-7.m-auto {
  border: 0 !important;
}

.form_main_box.px-3.py-3 {
  background: #fff;
  padding: 20px !important;
}

.form_main_box.px-3.py-3 label.form-label.file_title {
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  color: #4b7abe;
  margin-bottom: 5px;
}

.download_btn.mt-2.text-center a {
  background: linear-gradient(293.3deg, #506cb4 4.8%, #29aee4 95.03%);
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  transition: 0.4s;
  margin: 0 8px !important;
  font-size: 16px;
}

.display_file .download_btn .sign-button.btn-primary {
  margin: 0.5rem 8px 0px 8px !important;
}

body .btn-primary {
  padding: 8px 12px;
}

.form_main_box .mb-1 {
  margin-bottom: 15px !important;
}

.download_btn.mt-2.text-center a:hover {
  background: linear-gradient(293.3deg, #29aee4 4.8%, #506cb4 95.03%);
  transition: 0.4s;
}

body .display_form_data .form_main_box .display_file p {
  margin-bottom: 0;
}

.display_form_data .form_main_box .display_file p.file_name a {
  color: #231f20 !important;
}

.form_main_box .filesList.form-control.file_list_main {
  border: 0;
}

.form_main_box .display_file {
  margin: 0 -12px;
}

.form_main_box .filesList.form-control.file_list_main code {
  border: 0 !important;
  color: #231f20 !important;
  font-size: 14px;
}

.form_box.pdf.display_form_data
  .form_main_box
  .file_list_main
  .d-flex
  div.col-sm-3,
.form_box.pdf.display_form_data
  .form_main_box
  .file_list_main
  .d-flex
  div.col-sm-9 {
  margin-bottom: 0 !important;
}

.download_btn.mt-2.text-center {
  margin-top: 30px !important;
}

.form_box.pdf.display_form_data
  .form_main_box
  .d-flex.justify-content-center.align-items-center {
  margin-bottom: 3px !important;
}

body .fourth-column form button {
  position: absolute;
  top: 4px;
  right: 6px;
  width: auto;
  margin: 0;
  padding: 7px 12px;
}

.react-responsive-modal-modal .form-group .d-flex .d-flex.button_box {
  justify-content: flex-end;
  flex-direction: row;
}

.react-responsive-modal-modal .form-group .modal-width h6 {
  font-size: 16px;
  font-weight: 700;
}

.react-responsive-modal-modal .form-group .modal-width p {
  font-size: 14px;
}

.react-responsive-modal-modal {
  border-radius: 5px;
}

.react-responsive-modal-modal .form-group div {
  border-top: 1px solid #ececec !important;
}

.react-responsive-modal-modal .form-group div div {
  border: 0 !important;
}

.react-responsive-modal-modal .form-group div .mr-3 {
  font-size: 20px;
  font-weight: 700;
}

.react-responsive-modal-modal .form-group label {
  margin-bottom: 12px;
}

.modal-width p {
  margin: 0;
}

.react-responsive-modal-modal .form-group div.button_box {
  border: 0 !important;
  margin-top: 10px !important;
}

.width-sep {
  flex-wrap: wrap;
}

.width-sep h6 {
  font-size: 16px;
}

.react-responsive-modal-modal .form-group .d-flex .d-flex {
  flex-direction: column;
}

.react-responsive-modal-modal .form-group div.width-sep div {
  width: 100%;
  margin: 0 0 15px 0;
  word-break: break-all;
  border-bottom: 1px solid #ececec !important;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.react-responsive-modal-modal .form-group div.width-sep div p {
  margin-bottom: 0;
}

.react-responsive-modal-modal .form-group div .mr-3 {
  font-size: 16px;
}

.width-sep p {
  font-size: 14px;
}

.upcoming-feature-sec {
  padding-bottom: 100px;
}
.upcoming-feature-sec ol {
  margin-bottom: 40px;
}
.upcoming-feature-sec .nav-tabs {
  border: 0;
  display: inline-flex;
  margin-bottom: 30px;
}
.upcoming-feature-sec .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 12px 26px;
  border-radius: 0;
  text-transform: capitalize;
  background: transparent;
  color: #2db1ea;
  border: 0;
  border-bottom: 1px solid #ececec;
}
.upcoming-feature-sec .nav-tabs .nav-link.active {
  border-bottom: 2px solid #29aee4;
  transition: 0.4s;
}
.upcoming-feature-sec th,
.upcoming-feature-sec td {
  border: 1px solid #4b7abe !important;
  font-size: 16px;
  line-height: 22px;
}
.upcoming-feature-sec th {
  background-color: #f4f7ff;
}
.upcoming-feature-sec td p {
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  margin: 0;
}

i.icon-check,
i.icon-cross {
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
i.icon-check {
  background: url(./assets/images/icon_check.svg) no-repeat;
  background-size: cover;
}
i.icon-cross {
  background: url(./assets/images/icon_cross.svg) no-repeat;
  background-size: cover;
}
